<template>
  <div class="admin-playbooks-show">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Loading -->
    <div v-if="isLoading" class="text-center loading-wrapper">
      <a-spin />
    </div>
    <!-- / Loading -->

    <!-- Loaded -->
    <div v-if="!isLoading">
      <!-- Page Header -->
      <div class="page-header">
        <h1 class="page-title">
          <a-button
            type="default"
            style="display: inline; margin-right: 15px"
            @click.prevent="$router.push('/admin/settings/playbooks')"
            icon="left"
          ></a-button>

          <admin-org-indicator></admin-org-indicator>

          {{ title }}
        </h1>
        <div class="actions">
          <a-button
            class="button-margin-left btn-rounded"
            icon="edit"
            size="large"
            type="primary"
            @click.prevent="edit"
            v-if="canEdit"
            >Edit</a-button
          >

          <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="attemptDelete"
            v-if="canEdit"
          >
            <a-button
              class="button-margin-left btn-rounded"
              icon="delete"
              size="large"
              type="danger"
              >Delete</a-button
            >
          </a-popconfirm>
        </div>
      </div>
      <!-- / Page Header -->

      <!-- Tabs -->
      <a-tabs v-model="selectedTab">
        <a-tab-pane tab="Details" key="details"></a-tab-pane>
        <a-tab-pane tab="Tasks" key="tasks"></a-tab-pane>
      </a-tabs>
      <!-- / Tabs -->

      <!-- Content -->
      <div class="content">
        <div class="details" v-if="selectedTab == 'details'">
          <div>
            <b>Description:</b>
          </div>
          {{ playbook.description }}

          <div class="visibility">
            <b>Visibility:</b>
          </div>
          <span v-if="playbook.scope == 1">Shown to organisation</span>
          <span v-if="playbook.scope == 2">Shown to whole tenant</span>
          <span v-if="playbook.scope == 3">Hidden from lists</span>
        </div>
        <div class="tasks" v-if="selectedTab == 'tasks'">
          <playbook-tasks
            :can-edit="canEdit"
            :playbook="playbook"
            :org="selectedOrganisation"
            :tenant-id="tenantId"
          ></playbook-tasks>
        </div>
      </div>
      <!-- / Content -->
    </div>
    <!-- / Loaded -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import checklists from "../../../../api/checklists";
import PlaybookTasks from "./Show/Tabs/PlaybookTasks.vue";
export default {
  data() {
    return {
      isLoading: false,
      isDeleting: false,
      playbook: null,
      selectedTab: "details",
    };
  },
  components: { LoadingScreen, PlaybookTasks },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),

    canEdit() {
      return (
        this.playbook && this.playbook.ownerId == this.selectedOrganisation.id
      );
    },

    playbookId() {
      return this.$route.params.id;
    },

    title() {
      if (this.isLoading) {
        return "Loading...";
      }
      if (this.playbook && this.playbook.displayName) {
        return this.playbook.displayName;
      }
      return "Unknown";
    },
  },
  created() {
    this.loadPlaybook();
  },
  methods: {
    loadPlaybook() {
      let vm = this;
      vm.isLoading = true;
      vm.playbook = null;
      checklists
        .getChecklist(this.tenantId, this.playbookId)
        .then((r) => {
          vm.isLoading = false;
          vm.playbook = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading playbook");
          vm.$router.push("/admin/settings/playbooks");
        });
    },

    edit() {
      this.$router.push(
        "/admin/settings/playbooks/" + this.playbookId + "/edit"
      );
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      checklists
        .deleteChecklist(vm.tenantId, vm.playbookId)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("Playbook deleted successfully");
          vm.$router.push("/admin/settings/playbooks");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-playbooks-show {
  padding-bottom: 30px;

  .details {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #fff;

    b {
      margin-bottom: 5px;
      display: block;
    }

    .visibility {
      margin-top: 20px;
    }
  }
}

.button-margin-left {
  margin-left: 10px;
}
</style>