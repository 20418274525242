<template>
  <div style="display: inline-block">
    <a-button type="primary" icon="edit" @click.prevent="openModal">Edit</a-button>

    <a-modal width="900px" title="Edit Task" :footer="null" v-model="showModal"
      class="edit-task-in-playbook-button-and-modal">
      <div class="modal-inner" v-if="showModal">
        <div class="alert-wrapper">
          <a-alert type="info"
            message="A task has a short name and a longer detail. We can remind people about their incomplete tasks.">
          </a-alert>
        </div>

        <!-- Row 1 -->
        <a-row :gutter="30">
          <a-col :span="12">
            <a-form-item label="Task Summary (Short)">
              <a-input size="large" v-model="form.displayName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Description">
              <a-input size="large" v-model="form.description"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- / Row 1 -->

        <!-- Row 2 -->
        <div>
          <a-form-item label="Assign To Requester">
            <a-radio-group v-model="form.assignToInitiator" @change="forceUpdate">
              <a-radio :value="false">No</a-radio>
              <a-radio :value="true">Yes</a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
        <!-- / Row 2 -->

        <!-- Row 3 -->
        <a-row :gutter="30">
          <a-col :span="12">
            <a-form-item label="Remind If Incomplete After">
              <div class="slider-wrapper">
                <a-slider @change="forceUpdate" v-model="form.slaRemindHours" :tip-formatter="formatter" :min="0" :max="72"
                  :tooltipVisible="true" />
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="Report Late Task After">
              <div class="slider-wrapper">
                <a-slider @change="forceUpdate" v-model="form.slaLateHours" :tip-formatter="formatter" :min="0"
                  :max="72" :tooltipVisible="true" />
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- / Row 3 -->

        <a-button @click.prevent="attemptSave" type="primary" :disabled="isSaving">Save</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import checklistTasks from "../../../../../../../api/checklist-tasks";
export default {
  props: ["tenantId", "org", "playbook", "task"],
  created() {
    this.setForm();
  },
  data() {
    return {
      showModal: false,
      form: {
        displayName: "",
        description: "",
        assignToInitiator: false,
        slaRemindHours: 0,
        slaLateHours: 0,
      },
      isSaving: false,
    };
  },
  methods: {
    forceUpdate() {
      this.$forceUpdate();
    },

    // reset() {
    //   this.form = {
    //     displayName: "",
    //     description: "",
    //     assignToInitiator: false,
    //     slaRemindHours: 0,
    //     slaLateHours: 0,
    //   };
    // },

    openModal() {
      this.setForm();
      this.$nextTick(() => {
        this.showModal = true;
      });
    },

    closeModal() {
      this.showModal = false;
    },

    setForm() {
      this.form = { ...this.task };
      if (!this.form.slaRemindHours) {
        this.form.slaRemindHours = 0;
      }
      if (!this.form.slaLateHours) {
        this.form.slaLateHours = 0;
      }
      if (this.form.assignToInitiator == undefined) {
        this.form.assignToInitiator = false;
      } else {
        this.form.assignToInitiator = Boolean(this.form.assignToInitiator);
      }
    },

    formatter(value) {
      if (value == 0) {
        return "Never";
      }
      return `${value} ${value == 1 ? "hr" : "hrs"}`;
    },

    getValidationErrors() {
      let errors = [];
      if (this.form.displayName.trim().length == 0) {
        errors.push("Please provide a task summary");
      }
      if (this.form.description.trim().length == 0) {
        errors.push("Please provide a description");
      }
      return errors;
    },

    attemptSave() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.form
      };
      if (params.slaRemindHours == 0) {
        delete params.slaRemindHours;
      }
      if (params.slaLateHours == 0) {
        delete params.slaLateHours;
      }

      checklistTasks
        .updateChecklistTask(this.tenantId, params)
        .then(() => {
          vm.isSaving = false;
          //   vm.reset();
          vm.$message.success("Task updated successfully");
          vm.$emit("task-updated");
          vm.closeModal();
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error updating checklist task");
          vm.isSaving = false;
        });
    },
  },
};
</script>

<style lang="scss">
.edit-task-in-playbook-button-and-modal {
  .alert-wrapper {
    margin-bottom: 20px;
  }

  .slider-wrapper {
    padding-left: 25px;
    padding-right: 25px;

    .ant-slider {
      margin-top: 60px !important;
    }
  }
}
</style>